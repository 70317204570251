import { useSteps } from "react-step-builder";

import './stepInicio.scss'

import logo from '../../assets/logo_pf.svg'
import clock from '../../assets/clock.svg'
import Backdrop from "../../components/backdrop/backdrop";

export default function StepInicio(props) {
    const stepsState = useSteps();

    function onClickNextStep() {
        var url = window.location.href;
        var arr_url = url.split('?');
        var parametro = arr_url[1];
        if(parametro) {
            var arr_par = parametro.split('=');
            var display = arr_par[1];
        }else{
            alert('Falta Informar o Display');
            return false;
        }
        props.onChange('display', display)
        stepsState.next()
    }

    return (
        <div className="centered-container">
            <Backdrop />
            <img className="logo" src={logo} />
            <span className="title">Bem-vindo à nossa Pesquisa de Satisfação.</span>
            <span className="step-legend">Fique tranquilo! Não é necessário se identificar e será rapidinho.</span>

            <button className="button" onClick={onClickNextStep}>Iniciar</button>
            <span className="legend"><img src={clock}></img>Demora só um minutinho</span>
        </div>
    )
}